/* Auto layout */
. main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 1728px;
    height: 1005px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.first-part {
    box-sizing: border-box;
    padding: 20px;
    height: 100vh;
    min-height: 400px;
    background: #F8F9FA;
    border-right: 1px solid #DEE2E6;
    position: relative;
}

.message-box-area {
    height: 100vh;
    max-height: 400px;
}

.row {
    --bs-gutter-x: 0;
}

.callout {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    width: auto;
    height: 36px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.chat-room {
    box-sizing: border-box;
    padding: 13px 19px;
    height: 119px;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
}

.chat-room.active {
    border-width: 1px 1px 1px 10px;
    border-style: solid;
    border-color: #007BFF;
    cursor: default;
}

.sm-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.room-title {
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-number {

    width: 25px;
    height: 25px;
    background: #DC3545;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: white;
    float: right;
}

.badge-position {
    margin-top: -5px;
}

.badge-adjustments {
    font-size: 12px;
    padding: 3px 4px;
    color: white;
}
.badge-room-adjustments {
    font-size: 12px;
    padding: 3px 4px;
    color: white;
    position: relative;
    left: 20px;
}

.box-space {
    height: 50px;
}

.last-message {
    font-size: 13px;
}

.room-box-title {
    box-sizing: border-box;
    padding: 20px;
    gap: 30px;
    height: 58px;
    background: #FFFFFF;
    border-bottom: 1px solid #DFDFDF;
    position: fixed;
    top: 0;
    z-index: 10;

}

.room-box-container {
    padding: 46px 30px;
    padding-top: 0;
    height: 67vh;
    /*max-height: 550px;*/
}
.room-box-container-without-message-box {
    padding: 46px 30px;
    padding-top: 0;
    height: 92vh;
    /*max-height: 550px;*/
}
.room-empty-box-container {
    padding: 46px 30px;
    height: 90vh;
}
.room-participant-number {
    color: #ACACAC !important;
    font-size:16px;
    position:relative;
}

.message-sent-container {
    padding: 14px 20px;
    gap: 10px;
    max-width: 1011px;
    background: #F8F9FA;
    border-radius: 20px 20px 20px 0px;
    width: 100%;
}

.message-reply-container {
    padding: 14px 20px;
    gap: 10px;
    max-width: 1011px;
    background: #E1F0FF;
    border-radius: 20px 20px 0px 20px;
    width: 100%;
}

.room-title {
    font-weight: 700;
    font-size: 20px;
}

.mt-message-40 {
    margin-top: 40px;
}

.mt-message-container {
    margin-top: 8vh;
}

.mt-message-5 {
    margin-top: 5px;
}

.message-container {
    /*height: 200px;*/
    border-top: 1px solid #DFDFDF;
    background: #F8F9FA;
    width: 100%;

    bottom: 0;
    padding: 20px;
    position: absolute;
    /*height: 21vh;*/

}

.message-box {
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 10px;
    height: auto;
}

#message-box {
    resize: none;
}
.agreement-container {
    height:250px;
    overflow: auto;
    scrollbar-width: thin;
}

.pointer {
    cursor: pointer;
}

.modal-list {
    height: 250px;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #6C757D #EFEFEF;
}

.dropdown-item {
    padding: 10px 20px;

}
.dropdown-menu {
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    padding-top: 8px;
    padding-bottom: 10px;
}


.participant-user-avatar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 1px;
    top: 0;
    border: 2px solid #FFFFFF;
}

.display-option {
    display: block;
}

.alert-deleted-person {
    color:#DC3545;
    border: 1px solid #DC3545;
}
.alert-anchor, .alert-anchor:hover, .alert-anchor:visited {
    text-decoration:underline;
    color:#DC3545;
}
#scrollingBtn {
    background: #F8F9FA;
    border-radius: 4.8px;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.26));
}

.menu-title-box {
    background: #F8F9FA;
}
.menu-title-box-dropdown {
    position: absolute;
    left: -45px;
    background: #F1F1F1;
    color: #6C757D;
    border-color: #F1F1F1;
}

.contact-modal-label {
    font-size: 12px;
    font-weight: 700;

}
.contact-modal-span-1 {
    color: #686868;
}
.contact-modal-span-2 {
    color: #A9A9A9;
}

.reaction-emoji{
    cursor: pointer;
    margin: 3px;
    padding: 3px;
    border-radius: 4px;
}

.reaction-with-count{
    display: inline-block;
    height: 31px;
    margin-left: 4px;
    padding-left: 2px;
    padding-right: 6px;
    border-radius:4px;
    cursor: pointer;
    border: solid gray 1px;
    transition: .4s;

}
.reaction-emoji:hover{
    background: lightgray;
}

.done-color {
    color: #1e7e34;
}

.is-delivered {
    margin-left: -8px!important;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-arrow::before {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent; /* Adjust size and color as needed */
    border-right: 8px solid transparent;
    border-top: 8px solid black;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip {
    opacity: 1 !important; /* Set the opacity to 1 to make it fully opaque */
}
.tooltip-reaction-icon {
    padding: 5px;
    background-color: white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    align-self: center;
    margin-bottom: 5px;
}

.editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
}
.toolbar-class {
    border: 1px solid #ccc;
}
.rdw-editor-toolbar {
    border: unset !important;
}
.rdw-dropdown-wrapper {
    position: relative;
}

.rdw-dropdown-wrapper .rdw-dropdown-optionwrapper {
    position: absolute;
    bottom: 100%;
    margin-bottom: 10px; /* Adjust as needed */
    overflow-y: auto;
}

.rdw-link-modal, .rdw-emoji-modal, .rdw-embedded-modal, .rdw-colorpicker-modal {
    top: unset !important;
    bottom: 110%;
    max-height: 250px !important;
}
.rdw-link-modal, .rdw-embedded-modal, .rdw-colorpicker-modal {
    height: 250px !important;
}
.rdw-colorpicker-modal-options {
    overflow: unset;
    overflow-y: scroll;
}
.rdw-option-wrapper {
    border-radius: 3px !important;
    border: none !important;
}
.rdw-dropdown-wrapper {
    border-radius: 5px !important;
}
.rdw-dropdown-selectedtext {
    color: black !important;
}
.DraftEditor-root {
    min-height: 70px;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #6C757D #EFEFEF;
}
.public-DraftStyleDefault-block {
    margin: 4px 0px !important;
}
p {
    margin-bottom: 0;
}
.rdw-option-active {
    background-color: #E1F0FF !important;
}


@media screen and (max-width: 768px) {
    .display-option {
        display: none !important;
    }
    .message-container {
        position: relative;
    }
    .btn-cf, .menu-title-box-dropdown {
        font-size: 16px;
    }
    .offline-container {
        min-height: auto;
        height: auto !important;
        padding-bottom: 0;
    }
}
@media screen and (width: 768px) {
    .display-option {
        display: block !important;
    }
}
@media screen and (max-width: 522px) {
    .resp-date {
        width: 100%;
        justify-content: end !important;
    }
}

